import * as types from "../../types";

const user = {
  state: {
    showColumns: [],
  },
  mutations: {
    [types.SET_DATATABLE_SHOW_COLUMNS]: (state, columns) => {
      state.showColumns = columns;
    },
  },
};

export default user;
