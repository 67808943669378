import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import swal from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueToastr2 from "vue-toastr-2";
import "vue-toastr-2/dist/vue-toastr-2.min.css";
import moment from "moment";
import VTooltip from "v-tooltip";
import "viewerjs/dist/viewer.css";
import VueViewer from "v-viewer";
import VueClipboard from "vue-clipboard2";
import VueApexCharts from "vue-apexcharts";

// import SocketIO from "socket.io-client";
// import VueSocketIOExt from "vue-socket.io-extended";
// import { SOCKET_URL } from "@/config";

//config
moment.locale("vi");
window.toastr = require("toastr");
Vue.use(VueToastr2);
Vue.use(swal);
Vue.use(VTooltip);
Vue.use(VueViewer);
VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

Vue.config.productionTip = false;

// const socketInstance = SocketIO(SOCKET_URL, {
//   transports: ["websocket"],
// });
// Vue.use(VueSocketIOExt, socketInstance);

//filters
Vue.filter("formatNumber", function (x) {
  return x
    ? Math.round(Number(x))
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    : 0;
});
Vue.filter("timeAgo", function (x) {
  let time = moment(x).fromNow();
  return time.includes("Invalid") ? x : time;
});
Vue.filter("diffDays", function (x) {
  const from = moment().startOf("day");
  const to = moment(x);
  return from.diff(to, "days");
});
Vue.filter("formatDateTime", function (x) {
  return moment(x, "YYYY/MM/DD HH:mm:ss ZZ").format("DD-MM-YYYY");
});
Vue.filter("getLastWord", function (text) {
  return text.split(" ").pop();
});
Vue.filter("getFirstCharaterForName", function (text) {
  return text.split(" ").pop().charAt(0);
});

//app
new Vue({
  // sockets: {
  //   connect() {
  //     console.log("socket connected");
  //   },
  // },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  created() {
    // this.$socket.$subscribe("notification", (data) => {
    //   console.log(`socket notification`, data);
    // });
    // const user = this.user;
    // if (user) {
    //   this.$socket.client.emit("init_app", {
    //     user_id: user.id,
    //   });
    // }
  },
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
