const systemConfig = [
    {
        path: "/system-config",
        name: "SystemConfig",
        meta: { requiresAuth: true },
        component: () => import("@/views/system/SystemConfig.vue"),
    },
];

export default systemConfig;
