/** When your routing table is too long, you can split it into small modules* */

const statistics = [
  {
    path: "/statistics",
    name: "Statistics",
    meta: { requiresAuth: true },
    component: () => import("@/views/statistics/Statistics.vue"),
  },
  {
    path: "/statistics/top",
    name: "StatisticsTop",
    meta: { requiresAuth: true },
    component: () => import("@/views/statistics/StatisticsTop.vue"),
  },
  {
    path: "/statistics/revenues",
    name: "StatisticsTop",
    meta: { requiresAuth: true },
    component: () => import("@/views/statistics/StatisticsRevenues.vue"),
  },
  {
    path: "/statistics/by-username",
    name: "StatisticsUsername",
    meta: { requiresAuth: true },
    component: () => import("@/views/statistics/StatisticsUsername.vue"),
  },
  {
    path: "/statistics/by-username-detail",
    name: "StatisticsUsernameDetail",
    meta: { requiresAuth: true },
    component: () => import("@/views/statistics/StatisticsUsernameDetail.vue"),
  },
];

export default statistics;
