/** When your routing table is too long, you can split it into small modules* */

const logsWorker = [
  {
    path: "/logs-worker",
    name: "LogsWorker",
    meta: { requiresAuth: true },
    component: () => import("@/views/logs-worker/LogsWorker.vue"),
  },
];

export default logsWorker;
