/** When your routing table is too long, you can split it into small modules* */

const tool = [
  {
    path: "/tool",
    name: "Tool",
    meta: { requiresAuth: true },
    component: () => import("@/views/tool/Tool.vue"),
  },
];

export default tool;
