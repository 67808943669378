export const API_URL = window.location.href.includes("localhosts")
  ? "https://dev.api.autogolike.com"
  : //? "http://192.168.1.214/api.autogolike.com/public"
    "https://auto-api.autogolike.com";

export const SOCKET_URL = window.location.href.includes("localhost_messi")
  ? "https://localhost:3333"
  : "https://node.traodoicheo.net";

export const VERSION = "1.0.0";
