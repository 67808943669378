import router from "@/router";
import * as types from "../../types";

export default {
  [types.LOGIN]({ commit }, data) {
    try {
      const token = data.token;
      const user = data.data;
      commit(types.SET_TOKEN, token);
      commit(types.SET_USER, user);
      router.push("/");
    } catch (e) {
      throw e;
    }
  },

  [types.LOGOUT]({ commit }) {
    try {
      console.log("logout");
      commit(types.SET_TOKEN, {});
      commit(types.SET_USER, {});
      router.push("/login");
    } catch (e) {
      throw e;
    }
  },
};
