/** When your routing table is too long, you can split it into small modules* */

const logsTransfer = [
  {
    path: "/logs-transfer",
    name: "LogsTransfer",
    meta: { requiresAuth: true },
    component: () => import("@/views/logs-transfer/LogsTransfer.vue"),
  },
];

export default logsTransfer;
