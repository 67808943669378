/** When your routing table is too long, you can split it into small modules* */

const jobs = [
  {
    path: "/jobs-warranty",
    name: "JobsWarranty",
    meta: { requiresAuth: true },
    component: () => import("@/views/jobs-warranty/JobsWarranty.vue"),
  },
  {
    path: "/instagram/jobs",
    name: "JobsInstagram",
    meta: { requiresAuth: true },
    component: () => import("@/views/instagram/jobs/Jobs.vue"),
  },
  {
    path: "/facebook/jobs",
    name: "JobsFacebook",
    meta: { requiresAuth: true },
    component: () => import("@/views/jobs/Jobs.vue"),
  },
];

export default jobs;
