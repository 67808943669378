import Vue from "vue";
import Router from "vue-router";
import dashboardRouter from "./modules/dashboard";
import { loginRouter } from "./modules/auth";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    dashboardRouter,
    loginRouter,
    // registerRouter,
    { path: "*", redirect: "/", hidden: true },
  ],
});
