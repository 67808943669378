<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="vertical-menu">
    <div data-simplebar class="h-100">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu Start -->
        <ul class="metismenu list-unstyled" id="side-menu">
          <li
            v-for="(item, index) in menuItems"
            :key="index"
            :class="[`${item.title ? 'menu-title' : ''}`, `${item.active ? 'mm-active' : ''}`]"
            @click="activeMenu(item)"
          >
            {{ item.title }}

            <router-link
              :to="item.url"
              v-if="item.name && !item.children"
              class="`waves-effect"
              aria-expanded="false"
            >
              <i class="left-side-bar-icon" :class="item.icon"></i>
              <span key="t-icons">{{ item.name }}</span>
            </router-link>

            <a
              @click="item.collapse = !item.collapse"
              v-if="item.name && item.children"
              :class="[item.children ? 'has-arrow' : '', 'waves-effect']"
              aria-expanded="false"
            >
              <i class="left-side-bar-icon" :class="item.icon"></i>
              <span key="t-icons">{{ item.name }}</span>
            </a>

            <ul
              v-if="item.children"
              :class="`sub-menu ${item.collapse ? 'mm-collapse' : ''}`"
              aria-expanded="false"
            >
              <li v-for="(child, i) in item.children" :key="i">
                <router-link :to="child.url" key="t-boxicons">{{ child.name }}</router-link>
              </li>
            </ul>
          </li>
          <div class="version text-center mt-3">v{{ VERSION }}</div>
        </ul>
      </div>
      <!-- Sidebar -->
    </div>
  </div>
  <!-- Left Sidebar End -->
</template>

<script>
import menuItems from "@/config/menu";
import { LOGOUT } from "@/store/types";
import { VERSION } from "@/config";

export default {
  data() {
    return {
      VERSION,
      menuItems: [],
    };
  },
  created() {
    this.menuItems = menuItems
      .map((x) => {
        if (x.collapse === undefined) {
          x.collapse = true;
        }
        x.show = false;
        x.active = false;
        if (x.admin && !this.isAdmin) {
          return null;
        }
        return x;
      })
      .filter((x) => x);
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isAdmin() {
      return this.user.role === "admin";
    },
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT);
    },
    activeMenu(item) {
      this.menuItems = this.menuItems.map((x) => {
        if (x.name === item.name) {
          x.active = true;
        } else {
          x.active = false;
        }
        return x;
      });
    },
  },
};
</script>

<style lang="css" scoped>
#sidebar-menu {
  padding: 10px 0 30px 0;
  height: 95vh;
  overflow-y: scroll;
}
#sidebar-menu::-webkit-scrollbar {
  width: 5px;
}

#sidebar-menu::-webkit-scrollbar-thumb {
  background-color: #556EE6;
  border-radius: 6px;
}

#sidebar-menu::-webkit-scrollbar-track {
  background-color: #2a3042;
}
</style>
