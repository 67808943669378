import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import getters from "./getters";
import user from "./modules/user";
import datatable from "./modules/datatable";

Vue.use(Vuex);

const keyPersistedState = "AUTOGOLIKE-ADMIN";

export default new Vuex.Store({
  modules: {
    user,
    datatable,
  },
  getters,
  plugins: [
    createPersistedState({
      key: keyPersistedState,
    }),
  ],
});
