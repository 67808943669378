import actions from "./actions";
import * as types from "../../types";

const user = {
  state: {
    user: null,
    token: null,
  },

  mutations: {
    [types.SET_TOKEN]: (state, token) => {
      state.token = token;
    },
    [types.SET_USER]: (state, user) => {
      state.user = user;
    },
  },

  actions,
};

export default user;
