import store from "@/store";
import DashboardPage from "@/layouts/dashboard.vue";
import Home from "./home";
import account from "./account";
import logs from "./logs";
import logsTransfer from "./logs-transfer";
import logsWorker from "./logs-worker";
import logsGetJobs from "./logs-get-jobs";
import tool from "./tool";
import tracking from "./tracking";
import userRouter from "./user";
import systemConfig from "./system-config";
import withdraw from "./withdraw";
import blacklistFacebook from "./blacklist-facebook";
import jobs from "./jobs";
import statistics from "./statistics";
import accessToken from "./access-token";
import invalidName from "./invalid-name";

const dashboardRouter = {
    path: "/",
    component: DashboardPage,
    beforeEnter(to, from, next) {
        const token = store?.getters?.token;
        if (token) {
            next();
        } else {
            next("/login");
        }
    },
    redirect: {
        name: "Jobs",
    },
    children: [
        Home,
        ...account,
        ...logs,
        ...logsTransfer,
        ...logsWorker,
        ...logsGetJobs,
        ...tool,
        ...tracking,
        ...userRouter,
        ...systemConfig,
        ...withdraw,
        ...blacklistFacebook,
        ...invalidName,
        ...jobs,
        ...statistics,
        ...accessToken,
    ],
};

export default dashboardRouter;
