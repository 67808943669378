/** When your routing table is too long, you can split it into small modules* */

const log = [
    {
        path: "/logs-get-jobs",
        name: "LogsGetJobs",
        meta: { requiresAuth: true },
        component: () => import("@/views/logs-get-jobs/LogsGetJobs.vue"),
    },
];

export default log;
