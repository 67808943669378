import Vue from "vue";
import axios from "axios";
import store from "../store";
import { LOGOUT } from "../store/types";
import toastr from "toastr";
import { objectToQueryString } from "./url";
const VUE_APP_API_URL = process.env.VUE_APP_API_URL;

axios.defaults.timeout = 5000;
const defaults = {
  baseURL: VUE_APP_API_URL,
  headers: () => ({
    "Content-Type": "application/json; charset=utf-8",
    Authorization: `Bearer ` + store.getters.token,
  }),
  error: {
    code: "INTERNAL_ERROR",
    message: "Có lỗi xảy ra, vui lòng thử lại sau ít phút !",
    status: 503,
    data: {},
  },
};

const api = async (method, url, variables, options = { isShowToast: true }) => {
  return new Promise((resolve, reject) => {
    const headers = defaults.headers();
    if (variables && variables.formData instanceof FormData) {
      variables = variables.formData;
      headers["Content-Type"] = "multipart/form-data; charset=utf-8";
    }

    axios({
      url: `${!options.isOriginalUrl ? defaults.baseURL : ""}${url}`,
      method,
      headers,
      params: method === "get" ? variables : undefined,
      data: method !== "get" ? variables : undefined,
      paramsSerializer: objectToQueryString,
    })
      .then(
        (response) => {
          const { data } = response;
          if (data?.message) {
            toastr.success(response.data.message);
          }
          resolve(data);
        },
        (error) => {
          if (error?.response) {
            if (error.response?.data?.error) {
              toastr.error(error.response?.data?.error);
            }
            if (error.response?.data?.message) {
              Vue.swal.fire({
                type: "error",
                title: "Oops...",
                text: error.response.data.message,
                footer: "<a href>Why do I have this issue?</a>",
              });
            }
            switch (error.response?.status) {
              case 401:
                store.dispatch(LOGOUT);
                break;
              case 422:
                Vue.swal.fire({
                  type: "error",
                  title: "422 Error",
                  text: "Có lỗi xảy ra vui lòng liên hệ admin",
                });
                break;
            }
          }
          return reject(error.response?.data || error.response);
        }
      )
      .then(() => {});
  });
};

export default {
  get: (...args) => api("get", ...args),
  post: (...args) => api("post", ...args),
  put: (...args) => api("put", ...args),
  patch: (...args) => api("patch", ...args),
  delete: (...args) => api("delete", ...args),
};
