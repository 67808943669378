/** When your routing table is too long, you can split it into small modules* */

const homeRouter = {
  path: "/",
  name: "Home",
  meta: { requiresAuth: true },
  component: () => import("@/views/home/AdminHomePage.vue"),
};

export default homeRouter;
