/** When your routing table is too long, you can split it into small modules* */

const userRouter = [
  {
    path: "/user",
    name: "User",
    meta: { requiresAuth: true },
    component: () => import("@/views/user/user.vue"),
  },
];

export default userRouter;
