import store from "@/store";

export const loginRouter = {
  path: "/login",
  component: () => import("../../views/auth/login.vue"),
  beforeEnter(to, from, next) {
    if (store.getters.token) {
      next("/");
    } else {
      next();
    }
  },
};

export const registerRouter = {
  path: "/register",
  component: () => import("../../views/auth/register"),
};
