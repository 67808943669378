import request from "../utils/request";

const api = {
  read: async (id) => {
    const url = "/notifications/read/" + id;
    return request.post(url);
  },
  readAll: async () => {
    const url = "/notifications/read-all";
    return request.post(url);
  },
  list: async (params) => {
    const url = "/notifications";
    return request.get(url, params);
  },
};

export default api;
