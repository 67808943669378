/** When your routing table is too long, you can split it into small modules* */

const accessToken = [
  {
    path: "/access-token",
    name: "AccessToken",
    meta: { requiresAuth: true },
    component: () => import("@/views/access-token/AccessToken.vue"),
  },
];

export default accessToken;
