/** When your routing table is too long, you can split it into small modules* */

const blacklistFacebook = [
  {
    path: "/blacklist-facebook",
    name: "BlacklistFacebook",
    meta: { requiresAuth: true },
    component: () => import("@/views/blacklist-facebook/BlackListFacebook.vue"),
  },
];

export default blacklistFacebook;
