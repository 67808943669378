/** When your routing table is too long, you can split it into small modules* */

const logs = [
  {
    path: "/logs",
    name: "Logs",
    meta: { requiresAuth: true },
    component: () => import("@/views/logs/Logs.vue"),
  },
  {
    path: "/instagram/logs",
    name: "LogsInstagram",
    meta: { requiresAuth: true },
    component: () => import("@/views/instagram/logs/Logs.vue"),
  },
  {
    path: "/storage-logs",
    name: "StorageLogs",
    meta: { requiresAuth: true },
    component: () => import("@/views/logs/StorageLogs.vue"),
  },
];

export default logs;
