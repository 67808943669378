export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";

//auth
export const SET_TOKEN = "SET_TOKEN";
export const SET_USER = "SET_USER";

//statistics
export const GET_COIN_STATISTICS = "GET_COIN_STATISTICS";

//issue
export const SET_ISSUE = "SET_ISSUE";
export const SET_SPRINT = "SET_SPRINT";
export const SET_ISSUE_FILTERS = "SET_ISSUE_FILTERS";
export const SET_ISSUE_FILTERS_MULTI = "SET_ISSUE_FILTERS_MULTI";

//datatable
export const SET_DATATABLE_SHOW_COLUMNS = "SET_DATATABLE_SHOW_COLUMNS";
