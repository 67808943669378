export default [
  {
    title: "Menu",
  },
  {
    name: "Facebook",
    icon: "fab fa-facebook",
    collapse: false,
    children: [
      {
        name: "Quản lý Jobs",
        url: "/facebook/jobs",
      },
      {
        name: "Quản lý Account",
        url: "/account",
      },
      {
        name: "Blacklist Facebook",
        url: "/blacklist-facebook",
      },
      {
        name: "Danh sách tên ngoại",
        url: "/invalid-name/facebook",
      },
      {
        name: "Nhật ký làm việc",
        url: "/logs-worker",
      },
      {
        name: "Nhật ký Lấy Jobs",
        url: "/logs-get-jobs",
      },
    ],
  },
  {
    name: "Instagram",
    icon: "fab fa-instagram",
    collapse: false,
    children: [
      {
        name: "Quản lý Jobs",
        url: "/instagram/jobs",
      },
      {
        name: "Quản lý Accounts",
        url: "/instagram/accounts",
      },
      {
        name: "Quản lý Logs",
        url: "/instagram/logs",
      },
    ],
  },
  {
    name: "Quản lý User",
    icon: "far fa-user",
    url: "/user",
  },
  {
    name: "Quản lý Jobs Bảo Hành",
    icon: "fas fa-shield-alt",
    url: "/jobs-warranty",
  },
  {
    name: "Quản lý Logs",
    icon: "fas fa-history",
    url: "/logs",
  },
  {
    name: "Quản lý Access Token",
    icon: "fa fa-lock",
    url: "/access-token",
  },
  {
    name: "Chuyển - Rút tiền",
    icon: "fas fa-exchange-alt",
    collapse: false,
    children: [
      {
        name: "Quản lý chuyển xu",
        url: "/logs-transfer",
      },
      {
        name: "Quản lý rút tiền",
        url: "/withdraw",
      },
    ],
  },
  {
    name: "Thống Kê",
    icon: "fas fa-chart-line",
    collapse: false,
    children: [
      {
        name: "Tiền duyệt",
        url: "/statistics",
      },
      {
        name: "Top tuần",
        url: "/statistics/top",
      },
      {
        name: "Lợi nhuận",
        url: "/statistics/revenues",
      },
      {
        name: "Thống kê tài khoản",
        url: "/statistics/by-username",
      },
    ],
  },
  {
    name: "Cấu hình hệ thống",
    icon: "fas fa-cog",
    collapse: false,
    children: [
      {
        name: "Quản lý System Config",
        url: "/system-config",
      },
      {
        name: "Quản lý Tool",
        url: "/tool",
      },
      {
        name: "Log hệ thống",
        url: "/storage-logs",
      },
    ],
  },
  {
    name: "Quản lý Tracking",
    icon: "fab fa-airbnb",
    collapse: false,
    children: [
      {
        name: "Tracking Error",
        url: "/tracking-error",
      },
      {
        name: "Tracking Ip",
        url: "/tracking-ip",
      },
      {
        name: "Tracking Paid Job",
        url: "/tracking-paid-jobs",
      },
      {
        name: "Tracking Get Job",
        url: "/tracking-get-jobs",
      },
    ],
  },
];
