/** When your routing table is too long, you can split it into small modules* */

const invalidName = [
    {
        path: "/invalid-name/facebook",
        name: "InvalidFacebookName",
        meta: { requiresAuth: true },
        component: () => import("@/views/invalid-name/facebook.vue"),
    },
];

export default invalidName;
