/** When your routing table is too long, you can split it into small modules* */

const trackingError = [
  {
    path: "/tracking-error",
    name: "TrackingError",
    meta: { requiresAuth: true },
    component: () => import("@/views/tracking/error/TrackingError.vue"),
  },
  {
    path: "/tracking-ip",
    name: "TrackingIp",
    meta: { requiresAuth: true },
    component: () => import("@/views/tracking/ip/TrackingIp.vue"),
  },
  {
    path: "/tracking-paid-jobs",
    name: "TrackingPaidJobs",
    meta: { requiresAuth: true },
    component: () => import("@/views/tracking/paid-jobs/TrackingPaidJobs.vue"),
  },
  {
    path: "/tracking-get-jobs",
    name: "TrackingGetJobs",
    meta: { requiresAuth: true },
    component: () => import("@/views/tracking/get-jobs/TrackingGetJobs.vue"),
  },
];

export default trackingError;
