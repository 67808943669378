/** When your routing table is too long, you can split it into small modules* */

const account = [
  {
    path: "/account",
    name: "Account",
    meta: { requiresAuth: true },
    component: () => import("@/views/account/Account.vue"),
  },
  {
    path: "/instagram/accounts",
    name: "AccountInstagram",
    meta: { requiresAuth: true },
    component: () => import("@/views/instagram/accounts/Accounts.vue"),
  },
];

export default account;
