<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="/stoke/assets/images/logo.svg" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="/stoke/assets/images/logo-dark.png" alt="" height="17" />
            </span>
          </router-link>

          <router-link to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="/stoke/assets/images/logo-light.svg" alt="" height="22" />
            </span>
            <span class="logo-lg">
              <img src="/stoke/assets/images/logo-light1.png" alt="" height="40" />
            </span>
          </router-link>
          <!-- <div class="logo-text">Metech Work</div> -->
        </div>

        <button
          @click="toggleSidebar()"
          type="button"
          class="btn btn-sm px-3 font-size-16 header-item waves-effect"
          id="vertical-menu-btn"
        >
          <i class="fa fa-fw fa-bars"></i>
        </button>

        <!-- App Search-->
        <form class="app-search d-none d-lg-block">
          <div class="position-relative">
            <input type="text" class="form-control" placeholder="Tìm kiếm..." />
            <span class="bx bx-search-alt"></span>
          </div>
        </form>

        <div class="dropdown dropdown-mega d-none d-lg-block ms-2">
          <button
            type="button"
            class="btn header-item waves-effect"
            data-bs-toggle="dropdown"
            aria-haspopup="false"
            aria-expanded="false"
          >
            <span key="t-megamenu">Mega Menu</span>
            <i class="mdi mdi-chevron-down"></i>
          </button>
          <div class="dropdown-menu dropdown-megamenu">
            <div class="row">
              <div class="col-sm-8">
                <div class="row">
                  <div class="col-md-4">
                    <h5 class="font-size-14" key="t-ui-components">UI Components</h5>
                    <ul class="list-unstyled megamenu-list">
                      <li>
                        <a href="javascript:void(0);" key="t-lightbox">Lightbox</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-range-slider">Range Slider</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-sweet-alert">Sweet Alert</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-rating">Rating</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-forms">Forms</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-tables">Tables</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-charts">Charts</a>
                      </li>
                    </ul>
                  </div>

                  <div class="col-md-4">
                    <h5 class="font-size-14" key="t-applications">Applications</h5>
                    <ul class="list-unstyled megamenu-list">
                      <li>
                        <a href="javascript:void(0);" key="t-ecommerce">Ecommerce</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-calendar">Calendar</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-email">Email</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-projects">Projects</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-tasks">Tasks</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-contacts">Contacts</a>
                      </li>
                    </ul>
                  </div>

                  <div class="col-md-4">
                    <h5 class="font-size-14" key="t-extra-pages">Extra Pages</h5>
                    <ul class="list-unstyled megamenu-list">
                      <li>
                        <a href="javascript:void(0);" key="t-light-sidebar">Light Sidebar</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-compact-sidebar">Compact Sidebar</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-horizontal">Horizontal layout</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-maintenance">Maintenance</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-coming-soon">Coming Soon</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-timeline">Timeline</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-faqs">FAQs</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="row">
                  <div class="col-sm-6">
                    <h5 class="font-size-14" key="t-ui-components">UI Components</h5>
                    <ul class="list-unstyled megamenu-list">
                      <li>
                        <a href="javascript:void(0);" key="t-lightbox">Lightbox</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-range-slider">Range Slider</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-sweet-alert">Sweet Alert</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-rating">Rating</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-forms">Forms</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-tables">Tables</a>
                      </li>
                      <li>
                        <a href="javascript:void(0);" key="t-charts">Charts</a>
                      </li>
                    </ul>
                  </div>

                  <div class="col-sm-5">
                    <div>
                      <img
                        src="/stoke/assets/images/megamenu-img.png"
                        alt=""
                        class="img-fluid mx-auto d-block"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex">
        <div class="dropdown d-inline-block d-lg-none ms-2">
          <button
            type="button"
            class="btn header-item noti-icon waves-effect"
            id="page-header-search-dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="mdi mdi-magnify"></i>
          </button>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
            aria-labelledby="page-header-search-dropdown"
          >
            <form class="p-3">
              <div class="form-group m-0">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search ..."
                    aria-label="Recipient's username"
                  />
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="submit">
                      <i class="mdi mdi-magnify"></i>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="dropdown d-none d-lg-inline-block ms-1">
          <button
            type="button"
            class="btn header-item noti-icon waves-effect"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="bx bx-customize"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end">
            <div class="px-lg-2">
              <div class="row g-0">
                <div class="col">
                  <a class="dropdown-icon-item" href="#">
                    <img src="/stoke/assets/images/brands/github.png" alt="Github" />
                    <span>GitHub</span>
                  </a>
                </div>
                <div class="col">
                  <a class="dropdown-icon-item" href="#">
                    <img src="/stoke/assets/images/brands/bitbucket.png" alt="bitbucket" />
                    <span>Bitbucket</span>
                  </a>
                </div>
                <div class="col">
                  <a class="dropdown-icon-item" href="#">
                    <img src="/stoke/assets/images/brands/dribbble.png" alt="dribbble" />
                    <span>Dribbble</span>
                  </a>
                </div>
              </div>

              <div class="row g-0">
                <div class="col">
                  <a class="dropdown-icon-item" href="#">
                    <img src="/stoke/assets/images/brands/dropbox.png" alt="dropbox" />
                    <span>Dropbox</span>
                  </a>
                </div>
                <div class="col">
                  <a class="dropdown-icon-item" href="#">
                    <img src="/stoke/assets/images/brands/mail_chimp.png" alt="mail_chimp" />
                    <span>Mail Chimp</span>
                  </a>
                </div>
                <div class="col">
                  <a class="dropdown-icon-item" href="#">
                    <img src="/stoke/assets/images/brands/slack.png" alt="slack" />
                    <span>Slack</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="dropdown d-none d-lg-inline-block ms-1">
          <button
            type="button"
            class="btn header-item noti-icon waves-effect"
            data-toggle="fullscreen"
          >
            <i class="bx bx-fullscreen"></i>
          </button>
        </div>

        <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item noti-icon waves-effect"
            id="page-header-notifications-dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="bx bx-bell bx-tada"></i>
            <span v-show="notiCountUnread > 0" class="badge bg-danger rounded-pill">{{
              notiCountUnread
            }}</span>
          </button>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
            aria-labelledby="page-header-notifications-dropdown"
          >
            <div class="p-3">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="m-0" key="t-notifications">Thông báo</h6>
                </div>
                <div class="col-auto">
                  <a @click="readAll()" href="#!" class="small" key="t-view-all"> Đọc tất cả</a>
                </div>
              </div>
            </div>
            <div data-simplebar style="max-height: 70%">
              <a
                v-for="notification in notifications"
                :key="notification._id"
                :href="notification.url"
                class="text-reset notification-item"
                @click="readNotification(notification)"
              >
                <div class="d-flex">
                  <img
                    v-if="notification.image"
                    :src="notification.image"
                    class="me-3 rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                  <div v-else-if="notification.icon" class="avatar-xs me-3">
                    <span class="avatar-title bg-primary rounded-circle font-size-16">
                      <i :class="notification.icon"></i>
                    </span>
                  </div>
                  <div v-else class="avatar-xs me-3">
                    <span class="avatar-title bg-primary rounded-circle font-size-16">
                      <i class="bx bx-shield-quarter"></i>
                    </span>
                  </div>
                  <div class="flex-grow-1 text-break">
                    <h6
                      :class="`mb-1 notification-title ${
                        notification.read ? 'text-muted' : 'fw-bold'
                      }`"
                      key="t-your-order"
                    >
                      {{ notification.title }}
                    </h6>
                    <div :class="`font-size-12 ${notification.read ? 'text-muted' : 'fw-bold'}`">
                      <p class="mb-1 notification-content" key="t-grammer">
                        {{ notification.content }}
                      </p>
                      <p class="mb-0 float-end">
                        <i class="mdi mdi-clock-outline me-1"></i>
                        <span key="t-min-ago">{{ notification.createdAt | timeAgo }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div class="p-2 border-top d-grid">
              <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
                <i class="mdi mdi-arrow-right-circle me-1"></i>
                <span key="t-view-more">Xem thêm...</span>
              </a>
            </div>
          </div>
        </div>

        <div class="dropdown d-inline-block">
          <button
            type="button"
            class="btn header-item waves-effect"
            id="page-header-user-dropdown"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="d-inline avatar-group-item">
              <a href="javascript: void(0);" class="d-inline-block">
                <img
                  v-if="user.picture"
                  :src="user.picture"
                  alt=""
                  class="rounded-circle avatar-xs"
                />
                <div v-else class="avatar-xs">
                  <span
                    v-if="user.name"
                    class="avatar-title rounded-circle bg-info text-white font-size-16"
                  >
                    {{ user.name | getFirstCharaterForName }}
                  </span>
                </div>
              </a>
            </div>
            <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-end user-info">
            <!-- item-->
            <router-link to="#" class="dropdown-item">
              <i class="bx bx-user font-size-16 align-middle me-1"></i>
              <span key="t-profile">Cá nhân</span></router-link
            >
            <a class="dropdown-item d-block" href="#"
              ><span class="badge bg-success float-end">11</span
              ><i class="bx bx-wrench font-size-16 align-middle me-1"></i>
              <span key="t-settings">Cài đặt</span></a
            >
            <div class="dropdown-divider"></div>
            <a class="dropdown-item text-danger"
              ><i class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
              <span key="t-logout">Thoát</span></a
            >
          </div>
        </div>

        <div class="dropdown d-inline-block">
          <button type="button" class="btn header-item noti-icon right-bar-toggle waves-effect">
            <i class="bx bx-cog bx-spin"></i>
          </button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { LOGOUT } from "@/store/types";
import notificationApi from "@/api/notification";

export default {
  data() {
    return {
      notifications: [],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    notiCountUnread() {
      return this.notifications.filter((x) => !x.read).length;
    },
  },
  created() {
    // this.fetchNotifications();
  },
  methods: {
    toggleSidebar() {
      this.$emit("toggleSidebar");
    },
    logout() {
      this.$store.dispatch(LOGOUT);
    },
    async fetchNotifications() {
      const data = await notificationApi.list();
      if (data.data) {
        this.notifications = data.data.map((x) => {
          if (x.url && !x.url.includes("/detail")) {
            x.url += "/detail";
          }
          return x;
        });
        // console.log(this.notifications);
      }
    },
    async readNotification(notification) {
      const data = notificationApi.read(notification._id);
      if (data.data) {
        this.notifications = this.notifications.map((x) => {
          if (x._id === notification._id) {
            x.read = true;
          }
          return x;
        });
      }
    },
    async readAll() {
      const data = notificationApi.readAll();
      if (!data.error) {
        this.notifications = this.notifications.map((x) => {
          x.read = true;
          return x;
        });
      }
    },
  },
};
</script>

<style scoped>
.logo-text {
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 2rem;
}

.notification-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.notification-title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.dropdown-menu {
  max-height: 350px;
  overflow: auto;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(199, 199, 199);
  border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
