<template>
  <body
    data-sidebar="dark"
    :class="{ 'vertical-collpsed': verticalCollpsed, 'sidebar-enable': sidebarEnable }"
  >
    <div id="layout-wrapper">
      <Topbar @toggleSidebar="toggleSidebar" />

      <LeftSidebar />

      <div class="main-content">
        <router-view />

        <footer class="footer">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">2021 © Metech Software.</div>
              <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                  Dev with <i class="mdi mdi-heart text-danger"></i> by Dungqb
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </body>
</template>

<script>
import LeftSidebar from "./LeftSidebar.vue";
import Topbar from "./Topbar.vue";
import { LOGOUT } from "@/store/types";

export default {
  components: {
    LeftSidebar,
    Topbar,
  },
  data: function () {
    return {
      verticalCollpsed: false,
      sidebarEnable: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  watch: {
    $route(to, from) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.initSidebar();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT);
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    initSidebar() {
      if (this.windowWidth <= 1366 && this.windowWidth >= 992) {
        this.verticalCollpsed = true;
        this.sidebarEnable = true;
      }
    },
    toggleSidebar() {
      this.sidebarEnable = !this.sidebarEnable;
      if (this.windowWidth >= 992) {
        this.verticalCollpsed = this.sidebarEnable;
      } else {
        this.verticalCollpsed = false;
      }
    },
  },
};
</script>
